import Link from 'next/link';

import {
    BANNER_LINK,
    COMPANY_NAME,
    DESKTOP_BANNER,
    MOBILE_BANNER,
} from '@src/config';

export const Banner: React.FC = () => {
    if (!DESKTOP_BANNER || !MOBILE_BANNER) {
        return null;
    }

    return (
        <div className="mb-10 border border-custom rounded">
            <Link href={BANNER_LINK}>
                <a target="_blank">
                    <img
                        src={DESKTOP_BANNER}
                        alt={COMPANY_NAME}
                        className="hidden md:inline w-full rounded"
                    />
                    <img
                        src={MOBILE_BANNER}
                        alt={COMPANY_NAME}
                        className="md:hidden w-full rounded"
                    />
                </a>
            </Link>
        </div>
    );
};
