import React, { useState } from 'react';

import {
    Content,
    StakingAnalytics,
    StakingPools,
    Meta,
    Switch,
} from '@src/components';
import { useStakingState } from '@src/hooks';
import { Banner } from '@src/components/Banner';
import { HAS_BOTH_STAKING_LAYOUTS, STAKING_OPTIONS } from '@src/constants';
import { NFTAnalytics } from '@src/components/Staking/Analytics/NFTAnalytics';
import { getInitialStakingType } from '@src/utils/staking';

const Staking: React.FC = () => {
    const [type, setType] = useState(getInitialStakingType());
    const initial = useStakingState();
    const is_nft = type === 1;
    return (
        <Content>
            <Meta title="Staking" />

            {HAS_BOTH_STAKING_LAYOUTS && (
                <Switch
                    curr={type}
                    opts={STAKING_OPTIONS}
                    setCurr={(n) => setType(n)}
                    className="w-80 mb-8 mx-auto"
                />
            )}

            <Banner />

            {is_nft ? <NFTAnalytics /> : <StakingAnalytics />}

            <StakingPools initial={initial} is_nft={is_nft} />
        </Content>
    );
};

export default Staking;
