import { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useAppSelector } from '@src/hooks/index';
import { StakingPool, StakingType } from '@src/ts/interfaces';
import { formatLargeNumber } from '@src/utils/format';
import { DEFAULT_TOKEN } from '@src/config';
import { parseBalance } from '@src/utils/web3';

const ZERO = BigNumber.from(0);

export const useNFTAnalytics = () => {
    const pools = useAppSelector((state) =>
        state.staking.pools.filter(
            ({ type, reward_token: { address } }) =>
                type === StakingType.NFT && address === DEFAULT_TOKEN.address,
        ),
    );

    return useMemo(() => {
        const {
            total_staked,
            max_stakeable,
            total_rewards_earned,
            user_stake,
        } = reduceNFTPools(pools);

        const percent = total_staked
            .mul(100)
            .div(max_stakeable.eq(0) ? 1 : max_stakeable)
            .toNumber();

        return [
            {
                text: `${formatLargeNumber(
                    total_staked.toNumber(),
                )} / ${formatLargeNumber(max_stakeable.toNumber())}`,
                title: `Total staked (${percent.toFixed(0)}%)`,
            },
            {
                text: `${parseBalance(total_rewards_earned)} ${
                    DEFAULT_TOKEN.symbol
                }`,
                title: `Total rewards earned`,
            },
            {
                text: formatLargeNumber(user_stake.toNumber()),
                title: `Your stake`,
            },
        ];
    }, [pools]);
};

const reduceNFTPools = (pools: StakingPool[]) =>
    pools.reduce(
        (totals, pool) => {
            return {
                total_staked: totals.total_staked.add(pool.total_staked || 0),
                max_stakeable: totals.max_stakeable.add(pool.hardcap || 0),
                total_rewards_earned: totals.total_rewards_earned.add(
                    BigNumber.from(pool.earned_reward || 0).add(
                        pool.claimed_reward || 0,
                    ),
                ),
                user_stake: totals.user_stake.add(pool.user_stake || 0),
            };
        },
        {
            total_staked: ZERO,
            max_stakeable: ZERO,
            total_rewards_earned: ZERO,
            user_stake: ZERO,
        },
    );
