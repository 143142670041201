import React from 'react';

import { AnalyticsTextItem } from '../Item';

import { useNFTAnalytics } from './hooks';

export const NFTAnalytics: React.FC = () => {
    const text_items = useNFTAnalytics();

    return (
        <div className="bg-level-one rounded border border-custom space-y-4 lg:space-y-0 lg:flex lg:space-x-12 p-5">
            {text_items.map(({ text, title }) => (
                <AnalyticsTextItem key={title} title={title}>
                    {text}
                </AnalyticsTextItem>
            ))}
        </div>
    );
};
